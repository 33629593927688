import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Button,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* APIS */
import { getMeetingAbsence } from "service/api/absence.api";

/* ICONS */
import { IoClose } from "react-icons/io5";
import { GoKebabHorizontal } from "react-icons/go";

const MeetingAbsence = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [meetingList, setMeetingList] = useState([]);
  const [totalDocs, setTotalDocs] = useState([]);
  const [detailsDialog, setDetailsDialog] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(null);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-base font-ibm-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-base font-ibm-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  /* NO DATA SCREEN */
  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-base font-ibm-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  /* GET MEETING ABSENCE LIST */
  const getMeetingAbsenceList = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };
    getMeetingAbsence(params)
      .then((res) => {
        setMeetingList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getMeetingAbsenceList();
  };

  /* HANDLE PER PAGE CHANGE */
  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getMeetingAbsenceList();
  };

  /* USE-EFFECTS */
  useEffect(() => {
    getMeetingAbsenceList();
  }, [page, sizePerPage]);

  /* DATATABLE COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0,
      selector: (row, index) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      grow: 1.5,
      selector: (row) => (
        <div className="p-0">
          <p className="text-sm font-ibm-semibold text-black">{row.name}</p>
          <a
            href={`mailto:${row.email}`}
            className="text-sm font-ibm-regular text-greyText hover:text-logoRed transition-all duration-300 ease-in-out mt-1 whitespace-pre-wrap"
          >
            {row.email}
          </a>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => (
        <a
          href={`tel:${row.phoneNumber}`}
          className="block text-sm font-ibm-regular text-greyText hover:text-logoRed transition-all duration-300 ease-in-out"
        >
          {row.phoneNumber}
        </a>
      ),
    },
    {
      name: "Designation",
      selector: (row) => (
        <p className="text-sm font-ibm-semibold text-black text-opacity-60">
          {row.designation}
        </p>
      ),
    },
    {
      name: "Reason",
      grow: 2,
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-greyText leading-relaxed line-clamp-2">
          {row.reason}
        </p>
      ),
    },
    {
      name: "Date & Time",
      grow: 1,
      selector: (row) => (
        <div className="p-0">
          <span className="block text-sm font-ibm-regular text-black text-opacity-60">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
          <span className="block text-sm font-ibm-regular text-black text-opacity-60 mt-1">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </span>
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Menu>
            <MenuHandler>
              <Button className="shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-10 rounded-full border border-greyBorder p-2">
                <GoKebabHorizontal className="w-3 h-3 text-greyText rotate-90" />
              </Button>
            </MenuHandler>
            <MenuList className="min-w-[20vh] p-2">
              <MenuItem
                className="bg-white hover:bg-black hover:bg-opacity-10 rounded-md text-base font-ibm-regular text-black"
                onClick={() => {
                  setSelectedRegistration(row);
                  setDetailsDialog(true);
                }}
              >
                View details
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      ),
    },
  ];

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Registration Form Details | LMP Registration</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Meeting Absence
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              Handle the meeting absence records through the website.
            </h2>
          </div>
        </div>

        {/* DATATABLE */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? meetingList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
      </div>

      {/* MEETING ABSENCE DETAILS DIALOG */}
      <Dialog
        open={detailsDialog}
        handler={() => setDetailsDialog(!detailsDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="flex items-center px-5 py-3 justify-between border-b border-greyBorder">
            <h5 className="text-lg font-ibm-semibold text-black">
              View Details
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setDetailsDialog(!detailsDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            {selectedRegistration && (
              <div className="p-0">
                {/* NAME */}
                <div className="mb-4">
                  <p className="text-md font-ibm-regular text-greyText">
                    <span className="text-black font-ibm-semibold">Name:</span>{" "}
                    {selectedRegistration.name}
                  </p>
                </div>

                {/* EMAIL */}
                <div className="mb-4">
                  <p className="text-md font-ibm-regular text-greyText">
                    <span className="text-black font-ibm-semibold">Email:</span>{" "}
                    {selectedRegistration.email}
                  </p>
                </div>

                {/* PHONE */}
                <div className="mb-4">
                  <p className="text-md font-ibm-regular text-greyText">
                    <span className="text-black font-ibm-semibold">
                      Phone number:
                    </span>{" "}
                    {selectedRegistration.phoneNumber}
                  </p>
                </div>

                {/* WHATSAPP PHONE NUMBER */}
                <div className="mb-4">
                  <p className="text-md font-ibm-regular text-greyText">
                    <span className="text-black font-ibm-semibold">
                      Whatsapp Phone number:
                    </span>{" "}
                    {selectedRegistration.whatsAppPhoneNumber}
                  </p>
                </div>

                {selectedRegistration?.organization && (
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Organization:
                      </span>{" "}
                      {selectedRegistration?.organization}
                    </p>
                  </div>
                )}

                {/* ZONE */}
                <div className="mb-4">
                  <p className="text-md font-ibm-regular text-greyText">
                    <span className="text-black font-ibm-semibold">Zone:</span>{" "}
                    {selectedRegistration.zone}
                  </p>
                </div>

                {/* REGION, CITY */}
                <div className="flex items-center gap-10">
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Region:
                      </span>{" "}
                      {selectedRegistration.region}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        City:
                      </span>{" "}
                      {selectedRegistration.city}
                    </p>
                  </div>
                </div>

                {/* STATE */}
                <div className="mb-4">
                  <p className="text-md font-ibm-regular text-greyText">
                    <span className="text-black font-ibm-semibold">State:</span>{" "}
                    {selectedRegistration.state}
                  </p>
                </div>

                {/* REASON */}
                <div className="p-0">
                  <p className="text-md font-ibm-semibold text-black">
                    Reason:
                  </p>
                  <p className="text-md font-ibm-regular text-greyText mt-1">
                    {selectedRegistration?.reason}
                  </p>
                </div>
              </div>
            )}
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default MeetingAbsence;
