import request from "../request";

/* Scanners API */
// Add Scanner Details
export const postScannerDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`scanner/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Scanner List
export const getScannersList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/scanner`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Scanner Details By Id
export const getScannersDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`scanner/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// // Update Scanner Details By Id
// export const editScannersDetailsById = (id, body) => {
//   return new Promise(async (resolve, reject) => {
//     await request
//       .put(`scanner/` + id, body)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((e) => {
//         reject(e);
//       });
//   });
// };
// // Update Scanner Status By Id
// export const editScannersStatusById = (id, body) => {
//   return new Promise(async (resolve, reject) => {
//     await request
//       .patch(`scanner/` + id, body)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((e) => {
//         reject(e);
//       });
//   });
// };
// Remove Scanner Details By Id
export const deleteScannerDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`scanner/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
