import request from "../request";


/* Contact Us */
// Get Contact Us List
export const getContactUsList = (data, token) => {
    return new Promise(async (resolve, reject) => {
      await request.get('/contact-us', { params: data })
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
  // Delete Contact Us Details By Id
  export const deleteContactUsDetailsById = (id) => {
    return new Promise(async (resolve, reject) => {
      await request.delete(`/contact-us/` + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };