/* PACKAGES */
import axios from "axios";
import { LMP_URL } from "config";

const requestLMP = axios.create({
    baseURL: LMP_URL,
    timeout: 1000000,
  });

/* GET ZONES LIST */
export const getZonesAPI = (data) => {
    return new Promise(async (resolve, reject) => {
      await requestLMP
        .get(`/zone`, { params: data })
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };