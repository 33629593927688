import request from "../request";

/* Contact Us */
export const getMeetingAbsence = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/not-attending-delegate", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
