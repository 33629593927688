import { Helmet } from "react-helmet";

/*IMAGE */
import Cancel from "../../../../assets/images/cancel.png";
import { Button } from "@material-tailwind/react";

const ScannerError = () => {
  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Error | LMP Registration</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Scanning Not successfull
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              See the results here
            </h2>
          </div>
        </div>

        <div className="mt-5 rounded-md bg-white h-screen p-5">
          <div className="max-w-md w-full mx-auto rounded-lg shadow-md p-5 flex flex-col items-center border border-greyBorder">
            <img
              src={Cancel}
              alt="Verified"
              className="object-cover h-[10vh] w-[10vh]"
            />
            <p className="text-2xl font-proxima-bold pt-3 text-[#32BA7c]">
              Error
            </p>

            <div className="flex items-center gap-3 pt-5">
              <Button className="text-white bg-[#785ED9] normal-case px-3 py-3 rounded-md">
                Go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScannerError;
