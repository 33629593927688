/* ICONS */
import { TbNotebook } from "react-icons/tb";
import { IoIosCamera } from "react-icons/io";

const navMenu = [
  {
    id: 1,
    link: "/registration",
    icon: TbNotebook,
    name: "Registration Form",
  },
  {
    id: 2,
    link: "/awards-rsvp-request",
    icon: TbNotebook,
    name: "Awards RSVP",
  },
  {
    id: 3,
    link: "/meeting-absence",
    icon: TbNotebook,
    name: "Meeting absence",
  },
  {
    id: 4,
    link: "/scanner",
    icon: IoIosCamera,
    name: "Scanner",
  },
];

export default navMenu;
