import axios from 'axios';
import store from '../redux/store';
import { SERVER_URL } from '../config/index';

const request = axios.create({
  baseURL: SERVER_URL,
  timeout: 1000000,
});
let requests = [];
request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (store.getState().user?.token) {
      config.headers.Authorization = `Bearer ${store.getState().user?.token}`;
    }
    requests.push(config);
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);
export default request;







