import request from "../request";

/* Registration Details */
// Get Registration Form Detail List
export const getRegistrationList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/registration", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Patch Registration Form Details By Id
export const patchChangeStatusRegistrationDetailsById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`/registration/` + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Patch Registration Form Details By Id
export const patchBulkChangeStatusRegistrationDetailsById = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`/registration/bulk/edit`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Put Registration Form Details By Id
export const putEditRegistrationDetailsById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/registration/` + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Pa
// Delete Registration Form Details By Id
export const deleteRegistrationDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/registration/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
