import React from 'react';
import { saveAs } from 'file-saver';
import { Button } from '@material-tailwind/react';
import { CiExport } from "react-icons/ci";
const XLSX = require('xlsx');


const ExcelExport = ({ data, fileName, content }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <Button onClick={exportToExcel} className=" rounded-md font-ibm-semibold text-white bg-[#EF4444] hover:bg-opacity-80 px-3 py-3 text-base shadow-none hover:shadow-none normal-case transition-all duration-300 ease-in-out  ml-3 flex-shrink-0" type="button">
      <CiExport  name="icon ni ni-download" /> 
      {/* {content} */}
    </Button>

  );
}

export default ExcelExport;