import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";

/* COMPONENTS */
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Dialog,
  DialogBody,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";

/* ICONS */
import { IoClose } from "react-icons/io5";
import { GoKebabHorizontal } from "react-icons/go";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  getRegistrationList,
  patchChangeStatusRegistrationDetailsById,
} from "../../../service/api/registrationForm.api";

/* CONFIG URL */
import { IMAGE_URL } from "config";

const filterByType = [
  { value: null, label: "Clear filters" },
  {
    value: "KAROBARI",
    label: "Karobari Samiti and Madhyastha Mahasamiti Meeting",
  },
  {
    value: "MADHYASTHA",
    label: "Madhyastha Mahasamiti Meeting",
  },
];

const filterByStatus = [
  { value: null, label: "All" },
  { value: "approved", label: "Approved" },
  {
    value: "rejected",
    label: "Rejected",
  },
  {
    value: "pending",
    label: "Pending",
  },
];

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const RegistrationUs = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [registrationFormList, setRegistrationUsList] = useState([]);
  const [totalDocs, setTotalDocs] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [status, setStatus] = useState(null);
  const [datepicker, setDatepicker] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: "selection",
      color: "#ef4444",
    },
  ]);

  const [meetingType, setMeetingType] = useState(null);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getRegistrationListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getRegistrationListFunction();
  };

  const getRegistrationListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };
    if (search) {
      params.search = search;
    }

    if (status !== null) {
      params.status = status?.value;
    }

    if (meetingType !== null) {
      params.type = meetingType?.value;
    }
    if(state?.[0]?.startDate && state?.[0]?.endDate){
      params.startDate = state?.[0]?.startDate
      params.endDate = state?.[0]?.endDate
    }

    getRegistrationList(params)
      .then((res) => {
        setRegistrationUsList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE CHANGE STATUS */
  const handleStatusChange = (editId, type) => {
    patchChangeStatusRegistrationDetailsById(editId, { type })
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        getRegistrationListFunction();
      });
  };

  /* DATATABLE COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0,
      selector: (row, index) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      grow: 1.8,
      selector: (row) => (
        <div className="p-0">
          <p className="text-sm font-ibm-semibold text-black">{row.name}</p>
          <a
            href={`mailto:${row.email}`}
            className="text-sm font-ibm-regular text-greyText hover:text-logoRed transition-all duration-300 ease-in-out mt-1 whitespace-pre-wrap"
          >
            {row.email}
          </a>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => (
        <a
          href={`tel:${row.phoneNumber}`}
          className="block text-sm font-ibm-regular text-greyText hover:text-logoRed transition-all duration-300 ease-in-out"
        >
          {row.phoneNumber}
        </a>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <p className="text-sm font-ibm-semibold text-black text-opacity-60">
          {row?.status === 1 && (
            <span className="text-base font-ibm-semibold text-orange-500">
              Unpaid
            </span>
          )}
          {row?.status === 2 && (
            <span className="text-base font-ibm-semibold text-blue-500">
              Paid
            </span>
          )}
          {row?.status === 3 && (
            <span className="text-base font-ibm-semibold text-orange-500">
              Unpaid
            </span>
          )}
          {row?.status === 4 && (
            <span className="text-base font-ibm-semibold text-green-500">
              Approved
            </span>
          )}
          {row?.status === 5 && (
            <span className="text-base font-ibm-semibold text-red-500">
              Rejected
            </span>
          )}
          {row?.status === 6 && (
            <span className="text-base font-ibm-semibold text-blue-500">
              Paid & Email Sent
            </span>
          )}
        </p>
      ),
    },
    {
      name: "Type",
      grow: 2,
      selector: (row) => (
        <span className="block text-sm font-ibm-regular text-greyText pr-10 leading-relaxed">
          {row.type === "KAROBARI"
            ? "Karobari Samiti and Madhyastha Mahasamiti Meeting"
            : "Madhyastha Mahasamiti Meeting"}
        </span>
      ),
    },
    {
      name: "Date & Time",
      grow: 1,
      selector: (row) => (
        <div className="p-0">
          <span className="block text-sm font-ibm-regular text-black text-opacity-60">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
          <span className="block text-sm font-ibm-regular text-black text-opacity-60 mt-1">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </span>
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Menu>
            <MenuHandler>
              <Button className="shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-10 rounded-full border border-greyBorder p-2">
                <GoKebabHorizontal className="w-3 h-3 text-greyText rotate-90" />
              </Button>
            </MenuHandler>
            <MenuList className="min-w-[20vh] p-2">
              <MenuItem
                className="bg-white hover:bg-black hover:bg-opacity-10 rounded-md text-base font-ibm-regular text-black"
                onClick={() => {
                  setSelectedRegistration(row);
                  setViewModal(true);
                }}
              >
                View details
              </MenuItem>
              {(row?.status === 2 || row?.status === 6) && (
                <>
                  <MenuItem
                    className="bg-white hover:bg-black hover:bg-opacity-10 rounded-md text-base font-ibm-regular text-black"
                    onClick={() => {
                      handleStatusChange(row?._id, "approved");
                    }}
                  >
                    Approve registration
                  </MenuItem>
                  <MenuItem
                    className="bg-white hover:bg-black hover:bg-opacity-10 rounded-md text-base font-ibm-regular text-black"
                    onClick={() => {
                      setDeleteModal(!deleteModal);
                      setDeleteId(row?._id);
                    }}
                  >
                    Reject registration
                  </MenuItem>
                </>
              )}
              {/* {( row?.status === 6) && (
               
              )} */}
            </MenuList>
          </Menu>
        </div>
      ),
    },
  ];

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  /* DOM NODE */
  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  /* USE-EFFECTS */
  useEffect(() => {
    getRegistrationListFunction();
  }, [page, sizePerPage, search, status, meetingType, state]);

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Registration Form Details | LMP Registration</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Registration Form Details
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              Handle the registrations submitted through the website.
            </h2>
          </div>
          <div className="block xl:flex lg:flex items-center gap-2 mt-3 xl:mt-0 lg:mt-0">
            {/* DATE RANGE FILTER */}
            <div className="relative">
              <div ref={domNode}>
                <input
                  type="text"
                  name="dateRange"
                  placeholder="Filter by date range"
                  value={
                    state?.[0]?.startDate && state?.[0]?.endDate
                      ? ` ${state?.[0]?.startDate &&
                          moment(state?.[0]?.startDate).format(
                            "DD/MM/YYYY"
                          )} - ${state?.[0]?.endDate &&
                          moment(state?.[0]?.endDate).format("DD/MM/YYYY")}`
                      : "Select Date"
                  }
                  onClick={() => setDatepicker(!datepicker)}
                  className={`w-full xl:w-[25vh] lg:w-[25vh] bg-white outline-none font-ibm-regular text-${
                    state?.[0]?.startDate && state?.[0]?.endDate
                      ? "text-black"
                      : " text-gray-400"
                  } text-sm focus:outline-none border border-greyBorder rounded-md px-5 py-3`}
                />
              </div>
              {datepicker && (
                <div
                  ref={domNode}
                  className="absolute top-12 bottom-0 transition-all duration-300 ease-linear z-50"
                >
                  <DateRangePicker
                    dragSelectionEnabled
                    showPreview
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={true}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                    className="shadow-xl font-ibm-regular rounded-xl border border-greyBorder cursor-pointer"
                  />
                </div>
              )}
            </div>

            {/* FILTER BY TYPE */}
            <div className="w-full xl:w-[15vh] lg:w-[15vh] flex-shrink-0 mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={filterByStatus}
                className="react-select font-proxima-regular"
                classNamePrefix="filter"
                placeholder="Status"
                name="status"
                value={status}
                onChange={(e) => setStatus(e)}
              />
            </div>

            {/* FILTER BY TYPE */}
            <div className="w-full xl:w-[25vh] lg:w-[25vh] flex-shrink-0 mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={filterByType}
                className="react-select font-proxima-regular"
                classNamePrefix="filter"
                placeholder="Meeting Type"
                value={meetingType}
                onChange={(e) => setMeetingType(e)}
              />
            </div>

            {/* SEARCHBAR */}
            <div className="w-full xl:w-full lg:w-full mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        {/* DATATABLE */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            // selectableRows
            columns={columns}
            data={totalDocs > 0 ? registrationFormList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>

        {/* DELETE MODAL */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-bold text-black tracking-tight">
                Reject delegate
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-ibm-regular text-black">
                Are you sure, you want to reject this delegate from attending
                the meeting ?
              </p>
              <p className="text-base font-ibm-regular text-greyText mt-2">
                Note: Once you submit this form, the registration amount will be
                refunded to the delegate.
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={() => {
                  handleStatusChange(deleteId, "rejected");
                  setDeleteModal(!deleteModal);
                }}
                className="text-base shadow-none hover:shadow-none bg-[#EF4444] hover:bg-opacity-80 px-8 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Refund amount
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* VIEW DETAILS MODAL */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            {/* HEADER */}
            <div className="border-b border-gray-200 px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-semibold text-black">
                View Registration Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>

            {/* BODY */}
            <div className="p-5">
              {selectedRegistration && (
                <div className="p-0">
                  {/* NAME */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Name:
                      </span>{" "}
                      {selectedRegistration.name}
                    </p>
                  </div>

                  {/* EMAIL */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Email:
                      </span>{" "}
                      {selectedRegistration.email}
                    </p>
                  </div>

                  {/* PHONE */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Phone number:
                      </span>{" "}
                      {selectedRegistration.phoneNumber}
                    </p>
                  </div>

                  {/* WHATSAPP PHONE NUMBER */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Whatsapp Phone number:
                      </span>{" "}
                      {selectedRegistration.whatsAppPhoneNumber}
                    </p>
                  </div>

                  {selectedRegistration?.organization && (
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Organization:
                        </span>{" "}
                        {selectedRegistration?.organization}
                      </p>
                    </div>
                  )}

                  {/* ZONE */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Zone:
                      </span>{" "}
                      {selectedRegistration.zone}
                    </p>
                  </div>

                  {/* REGION, CITY */}
                  <div className="flex items-center gap-10">
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Region:
                        </span>{" "}
                        {selectedRegistration.region}
                      </p>
                    </div>
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          City:
                        </span>{" "}
                        {selectedRegistration.city}
                      </p>
                    </div>
                  </div>

                  {/* STATE */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        State:
                      </span>{" "}
                      {selectedRegistration.state}
                    </p>
                  </div>

                  {/* QR CODE */}
                  {selectedRegistration?.qrCode && (
                    <div className="mb-4">
                      <img
                        src={IMAGE_URL + selectedRegistration?.qrCode}
                        className=" w-44"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default RegistrationUs;
