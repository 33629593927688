import { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import moment from "moment";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { FiTrash } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  deleteContactUsDetailsById,
  getContactUsList,
} from "../../../service/api/contactUs.api";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ContactUs = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [contactUsList, setContactUsList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getContactUsListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getContactUsListFunction();
  };

  const getContactUsListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };
    if (search) {
      params.search = search;
    }

    getContactUsList(params)
      .then((res) => {
        setContactUsList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteContactUsDetailsById(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getContactUsListFunction();
      });
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      width: "10vh",
      selector: (row, index) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      width: "25vh",
      grow: 2,
      selector: (row) => (
        <div className="flex items-center gap-2">
          {/* <div className="w-10 h-10 rounded-full flex items-center justify-center bg-logoYellow text-sm font-proxima-semibold text-white">
          {row.name} 
          </div> */}
          <p className="text-sm font-proxima-semibold text-black text-opacity-60">
            {row.name}
          </p>
        </div>
      ),
    },
    {
      name: "Email",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.email}
        </span>
      ),
    },
    {
      name: "Phone",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.phoneNumber}
        </span>
      ),
    },
    {
      name: "Company Name",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.companyName}
        </span>
      ),
    },
    {
      name: "Type",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.type}
        </span>
      ),
    },
    {
      name: "Date & Time",
      width: "25vh",
      selector: (row) => (
        <div className="p-0">
          <span className="block text-sm font-ibm-regular text-black text-opacity-60">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
          <span className="block text-sm font-ibm-regular text-black text-opacity-60 mt-1">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </span>
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip content="View" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setSelectedContact(row);
                setViewModal(true);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <MdOutlineRemoveRedEye className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
          <Tooltip content="Remove" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getContactUsListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Contact Us Queries | LMP Registration</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-proxima-bold text-black tracking-tight">
              Contact Us
            </h1>
            <h2 className="text-base font-proxima-regular text-greyText">
              Handle and respond to user queries submitted through our website.
            </h2>
          </div>
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-3/4 xl:w-full lg:w-full mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        {/* Data table */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? contactUsList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>

        {/* Delete modal */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Delete Contact Us Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-proxima-regular text-black">
                Are you sure you want to delete this details?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* View modal */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            {/* Dialog Header */}
            <div className="border-b border-gray-200 px-5 py-3 flex items-center justify-between bg-white">
              <h5 className="text-lg font-proxima-semibold text-gray-800 tracking-tight">
                View Contact Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>

            {/* Dialog Body */}
            <div className="p-5 border-b border-gray-200 bg-white">
              {selectedContact && (
                <div className="p-5">
                  <div className="mb-4">
                    <p className="text-base font-proxima-semibold">
                      Company Name:
                    </p>
                    <p className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.companyName}
                    </p>
                  </div>

                  <div className="mb-4">
                    <p className="text-base font-proxima-semibold">Email:</p>
                    <p className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.email}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-base font-proxima-semibold">Name:</p>
                    <p className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.name}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-base font-proxima-semibold">
                      Phone Number:
                    </p>
                    <p className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.phoneNumber}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-base font-proxima-semibold">
                      Project Details:
                    </p>
                    <p className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.projectDetails}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-base font-proxima-semibold">
                      Schedule Call:
                    </p>
                    <p className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.scheduleCall ? "Yes" : "No"}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-base font-proxima-semibold">Status:</p>
                    <p className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.status}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-base font-proxima-semibold">Type:</p>
                    <p className="text-base font-proxima-regular text-black">
                      {selectedContact.type}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-base font-proxima-semibold">
                      Date & Time:
                    </p>
                    <p className="text-base font-proxima-regular text-black">
                      {" "}
                      {moment(selectedContact.createdAt).format(
                        "MMM DD, YYYY HH:MM A"
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default ContactUs;
