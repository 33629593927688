import { useState, useEffect, useRef } from "react";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

import { DateRangePicker } from "react-date-range";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  Tooltip,
} from "@material-tailwind/react";

/* ICONS */
import { FiTrash } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

import { Scanner } from "@yudiel/react-qr-scanner";
import Scan from "../../../assets/images/scan.png";

/* API IMPORT */
import {
  postScannerDetails,
  getScannersList,
  deleteScannerDetails,
} from "service/api/scanner.api";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IMAGE_URL } from "config";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const ContactUs = () => {
  const navigate = useNavigate();

  /* CONSTANTS */
  const today = new Date();
  const hrs = today.getHours();

  /* DOM NODE */
  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [scannerList, setScannerList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(null);

  const [isScanning, setIsScanning] = useState(false);
  const [data, setData] = useState(null);

  const [qrType, setQrType] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [datepicker, setDatepicker] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59)),
      key: "selection",
      color: "#ef4444",
    },
  ]);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getContactUsListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getContactUsListFunction();
  };

  const getContactUsListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: false,
    };
    if (search) {
      params.search = search;
    }
    if (state?.[0]?.startDate && state?.[0]?.endDate) {
      params.startDate = new Date(
        new Date(state?.[0]?.startDate).setHours(0, 0, 0)
      );
      params.endDate = new Date(
        new Date(state?.[0]?.endDate).setHours(23, 59, 59)
      );
    }

    getScannersList(params)
      .then((res) => {
        // setScannerList(res?.data?.docs);
        // setTotalDocs(res?.data?.totalDocs);
        setScannerList(res?.data);
        setTotalDocs(res?.data?.length);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteScannerDetails(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getContactUsListFunction();
      });
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0,
      selector: (row, index) => (
        <span className="text-sm font-ibm-semibold text-greyText">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      grow: 1,
      selector: (row) => (
        <div className="flex items-center gap-2">
          <p className="text-sm font-ibm-semibold text-black capitalize">
            {row?.registrationId?.name}
          </p>
        </div>
      ),
    },
    {
      name: "Email & Phone",
      grow: 2,
      selector: (row) => (
        <>
          <a
            href={`mailto:${row?.registrationId?.email}`}
            className="block text-sm font-ibm-regular text-blue-400 underline"
          >
            {row?.registrationId?.email}
          </a>
          <a
            href={`tel:${row?.registrationId?.phoneNumber}`}
            className="mt-1 block text-sm font-ibm-regular text-blue-400 underline"
          >
            {row?.registrationId?.phoneNumber}
          </a>
        </>
      ),
    },
    {
      name: "Meeting Type",
      grow: 2,
      selector: (row) => (
        <span className="block text-sm font-ibm-regular text-greyText pr-10 leading-relaxed">
          {row?.registrationId?.type === "KAROBARI"
            ? "Karobari Samiti and Madhyastha Mahasamiti Meeting"
            : "Madhyastha Mahasamiti Meeting"}
        </span>
      ),
    },
    {
      name: "Scanned For",
      selector: (row) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {row.type}
        </span>
      ),
    },
    {
      name: "Date & Time",
      selector: (row) => (
        <div className="p-0">
          <span className="block text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
          <span className="block text-sm font-ibm-regular text-greyText mt-1">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </span>
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip content="View" className="text-sm font-ibm-semibold">
            <Button
              onClick={() => {
                setSelectedRegistration(row?.registrationId);
                setViewModal(true);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <MdOutlineRemoveRedEye className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
          <Tooltip content="Remove" className="text-sm font-ibm-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-ibm-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  // Handle QR scan result
  const handleScan = async (detectedCodes) => {
    if (detectedCodes && detectedCodes.length > 0) {
      console.log("scanned data", detectedCodes);

      if (detectedCodes?.[0]?.rawValue) {
        await handleScannerApi(detectedCodes?.[0]?.rawValue);
      }
      setData(detectedCodes[0].rawValue);
      setIsScanning(false);
      // navigate("/scanner/success");
    }
  };

  // Handle errors
  const handleError = (error) => {
    console.error("Scanner error:", error);
  };

  // Toggle scanning state
  const toggleScanner = () => {
    setIsScanning(!isScanning);
    setData(null); // Reset result when scanner is toggled
  };

  const handleScannerApi = async (value) => {
    setLoading(true);
    postScannerDetails({ token: value, type: qrType })
      .then((res) => {
        console.log("res", res);
        if (res?.status) {
          toast.success(res?.message);
          getContactUsListFunction();
          setErrorMessage(res?.message);
          navigate("/scanner/success/" + res?.data?._id);
          // navigate("/success/"+res?.data?.registrationId);
        } else {
          toast.error(res?.message);
          setErrorMessage(res?.message);
          // navigate("/scanner/error/"+res?.data?.registrationId + "?error="+res?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("er", e?.response?.data?.message);
        if (e?.response?.data?.message) {
          toast.error(e?.response?.data?.message);
          setErrorMessage(e?.response?.data?.message);
          // navigate("/scanner/error/"+e?.data?.registrationId + "?error="+e?.response?.data?.message?.message);
        } else {
          toast.error(e?.message);
          setErrorMessage(e?.message);
          // navigate("/scanner/error/"+e?.data?.registrationId + "?error="+e?.message);
        }
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  /* USE-EFFECTS */
  useEffect(() => {
    getContactUsListFunction();
  }, [page, sizePerPage, state]);

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 ">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Contact Us Queries | LMP Registration</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Scanner
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              Scan the QR codes to verify the details
            </h2>
          </div>

          {/* SCAN RESULT */}
          <div className="mt-3 xl:mt-0 lg:mt-0 bg-white border border-greyBorder rounded-md shadow py-3 px-5">
            <div className="flex items-center ">
              <p className="text-md font-ibm-regular text-greyText">
                Scan Result:{" "}
                <span className="text-lg font-ibm-semibold text-black">
                  {!errorMessage ? "Pending" : errorMessage}
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* SCANNER DETAILS */}
        <Card className="mt-5 bg-white shadow border border-greyBorder rounded-md">
          <CardBody className="p-3 xl:p-5 lg:p-5">
            <div className="block xl:flex lg:flex items-center">
              <div className="pr-0 xl:pr-3 lg:pr-3 border-0 xl:border-r lg:border-r border-greyBorder">
                {isScanning ? (
                  <Scanner
                    onScan={handleScan}
                    onError={handleError}
                    constraints={{ facingMode: "environment" }}
                    scanDelay={300}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <img
                    src={Scan}
                    alt="Scan"
                    className="h-[30vh] xl:h-28 lg:h-28 w-[30vh] xl:w-28 lg:w-28 mx-auto"
                  />
                )}
              </div>
              <div className="mt-3 xl:mt-0 lg:mt-0 pl-0 xl:pl-3 lg:pl-3">
                <h5 className="text-lg font-ibm-bold text-black">
                  Scan QR Code for Entry and Meals
                </h5>
                <p className="mt-1 text-base font-ibm-regular text-greyText">
                  Click Below to Scan the QR Code for attendee verification, and
                  Meals access!
                </p>
                <div className="mt-3 block xl:flex lg:flex items-center gap-3">
                  {isScanning === true && qrType !== "Entry" ? (
                    ""
                  ) : (
                    <Button
                      onClick={() => {
                        setQrType("Entry");
                        toggleScanner();
                      }}
                      disabled={loading ? true : false}
                      className="w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-logoRed hover:bg-opacity-70 transition-all duration-300 ease-in-out rounded-md normal-case font-ibm-semibold text-sm"
                    >
                      {isScanning
                        ? "Stop Scanning For Entry"
                        : "Scan QR Code For Entry"}
                    </Button>
                  )}

                  {isScanning === true &&
                  qrType !== "Lunch" &&
                  qrType !== "Dinner" ? (
                    ""
                  ) : (
                    <Button
                      onClick={() => {
                        setQrType(
                          hrs < 10
                            ? "Breakfast"
                            : hrs >= 11 && hrs <= 15
                            ? "Lunch"
                            : hrs >= 18 && hrs <= 23
                            ? "Dinner"
                            : "Meal"
                        );
                        toggleScanner();
                      }}
                      disabled={loading ? true : false}
                      className="mt-2 xl:mt-0 lg:mt-0 w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-logoRed hover:bg-opacity-70 transition-all duration-300 ease-in-out rounded-md normal-case font-ibm-semibold text-sm"
                    >
                      {isScanning
                        ? "Stop Scanning For Lunch"
                        : `Scan QR Code For ${
                            hrs < 10
                              ? "Breakfast"
                              : hrs >= 11 && hrs <= 16
                              ? "Lunch"
                              : hrs >= 18 && hrs <= 23
                              ? "Dinner"
                              : "Meal"
                          }`}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        {/* OLD CODE */}
        <div className="hidden mt-5 rounded-md bg-white  p-5 grid-cols-9 gap-5">
          <Button
            className="bg-black px-5 py-2 rounded-md text-white normal-case col-span-4 md:col-span-2 lg:col-span-2"
            onClick={() => {
              setQrType("Entry");
              toggleScanner();
            }}
            disabled={loading ? true : false}
          >
            {isScanning ? "Stop Scanning" : "Scan QR Code For Entry"}
          </Button>

          <Button
            className="bg-black px-5 py-3  rounded-md text-white normal-case col-span-4 md:col-span-2 lg:col-span-2"
            onClick={() => {
              setQrType(
                hrs < 10
                  ? "Breakfast"
                  : hrs >= 11 && hrs <= 16
                  ? "Lunch"
                  : hrs >= 18 && hrs <= 23
                  ? "Dinner"
                  : "Meal"
              );
              toggleScanner();
            }}
            disabled={loading ? true : false}
          >
            {isScanning
              ? "Stop Scanning"
              : `Scan QR Code For ${
                  hrs < 10
                    ? "Breakfast"
                    : hrs >= 11 && hrs <= 16
                    ? "Lunch"
                    : hrs >= 18 && hrs <= 23
                    ? "Dinner"
                    : "Meal"
                }`}
          </Button>

          <div className="w-full max-w-md mx-auto mt-5 col-span-9">
            {/* {isScanning ? (
              <Scanner
                onScan={handleScan}
                onError={handleError}
                constraints={{ facingMode: "environment" }}
                scanDelay={300}
                style={{ width: "100%" }}
              />
            ) : (
              <img src={Scan} alt="Scan" className="h-[40vh] w-[40vh]" />
            )} */}
          </div>

          {/* Scan result */}
          <div className="mt-4 p-4 bg-green-100 text-green-800 rounded-lg border border-green-300  col-span-9 md:col-span-3 lg:col-span-3 ">
            <strong>Scan Result:</strong>{" "}
            {!errorMessage ? "Pending" : errorMessage}
          </div>
        </div>

        {/* HEADING */}
        <div className="mt-5">
          <div className="block xl:flex lg:flex items-center justify-between mt-3">
            <h5 className="text-lg font-ibm-semibold text-black">
              List of scanned entries
            </h5>

            {/* SEARCHBAR */}
            <div className=" mt-2 xl:mt-0 lg:mt-0 flex gap-2">
              {/* DATE RANGE FILTER */}
              <div className="relative">
                <div ref={domNode}>
                  <input
                    type="text"
                    name="dateRange"
                    placeholder="Filter by date range"
                    value={
                      state?.[0]?.startDate && state?.[0]?.endDate
                        ? ` ${state?.[0]?.startDate &&
                            moment(state?.[0]?.startDate).format(
                              "DD/MM/YYYY"
                            )} - ${state?.[0]?.endDate &&
                            moment(state?.[0]?.endDate).format("DD/MM/YYYY")}`
                        : "Select Date"
                    }
                    onClick={() => setDatepicker(!datepicker)}
                    className={`w-full xl:w-[25vh] lg:w-[25vh] bg-white outline-none font-ibm-regular text-${
                      state?.[0]?.startDate && state?.[0]?.endDate
                        ? "text-black"
                        : " text-gray-400"
                    } text-sm focus:outline-none border border-greyBorder rounded-md px-5 py-3`}
                  />
                </div>
                {datepicker && (
                  <div
                    ref={domNode}
                    className="absolute top-12 bottom-0 left-0 lg:left-auto right-auto lg:right-0 transition-all duration-300 ease-linear z-50"
                  >
                    <DateRangePicker
                      dragSelectionEnabled
                      showPreview
                      onChange={(item) => setState([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={true}
                      months={1}
                      ranges={state}
                      direction="horizontal"
                      preventSnapRefocus={true}
                      calendarFocus="backwards"
                      className="shadow-xl font-ibm-regular rounded-xl border border-greyBorder cursor-pointer"
                    />
                  </div>
                )}
              </div>

              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>

          {/* DATA TABLE */}
          <div className="mt-3">
            <DataTable
              className="data-table"
              columns={columns}
              data={
                totalDocs > 0
                  ? scannerList?.filter(
                      (element) =>
                        new RegExp(search, "i").test(
                          element?.registrationId?.name
                        ) ||
                        new RegExp(search, "i").test(
                          element?.registrationId?.email
                        ) ||
                        new RegExp(search, "i").test(
                          element?.registrationId?.phoneNumber
                        )
                    )
                  : []
              }
              progressPending={loading}
              progressComponent={<LoadingScreen />}
              pagination={false}
              paginationServer
              paginationTotalRows={totalDocs}
              onChangeRowsPerPage={handlePerPageChange}
              onChangePage={handlePageChange}
              noDataComponent={<NoDataScreen />}
            />
          </div>
        </div>

        {/* DELETE DETAILS MODAL */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-bold text-black tracking-tight">
                Delete Contact Us Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-ibm-regular text-black">
                Are you sure you want to delete this details?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* VIEW DETAILS MODAL */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            {/* Dialog Header */}
            <div className="border-b border-gray-200 px-5 py-3 flex items-center justify-between bg-white">
              <h5 className="text-lg font-ibm-semibold text-gray-800 tracking-tight">
                View Registration Details{" "}
                {selectedRegistration?.registrationId &&
                  `- (Registration ID: ${selectedRegistration?.registrationId})`}
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>

            <div className="p-5">
              {selectedRegistration && (
                <div className="p-0">
                  {/* NAME */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Name:
                      </span>{" "}
                      {selectedRegistration.name}
                    </p>
                  </div>

                  {/* EMAIL */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Email:
                      </span>{" "}
                      {selectedRegistration.email}
                    </p>
                  </div>

                  {/* PHONE */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Phone number:
                      </span>{" "}
                      {selectedRegistration.phoneNumber}
                    </p>
                  </div>

                  {/* WHATSAPP PHONE NUMBER */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Whatsapp Phone number:
                      </span>{" "}
                      {selectedRegistration.whatsAppPhoneNumber}
                    </p>
                  </div>

                  {selectedRegistration?.organization && (
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Organization:
                        </span>{" "}
                        {selectedRegistration?.organization}
                      </p>
                    </div>
                  )}

                  {selectedRegistration?.standingCommittee && (
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Standing Committee:
                        </span>{" "}
                        {selectedRegistration?.standingCommittee}
                      </p>
                    </div>
                  )}

                  {/* ZONE */}
                  {selectedRegistration?.designation && (
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Designation:
                        </span>{" "}
                        {selectedRegistration?.designation}
                      </p>
                    </div>
                  )}

                  {/* ZONE */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Zone:
                      </span>{" "}
                      {selectedRegistration.zone}
                    </p>
                  </div>

                  {/* REGION, CITY */}
                  <div className="flex items-center gap-10">
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Region:
                        </span>{" "}
                        {selectedRegistration.region}
                      </p>
                    </div>
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          City:
                        </span>{" "}
                        {selectedRegistration.city}
                      </p>
                    </div>
                  </div>

                  {/* STATE */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        State:
                      </span>{" "}
                      {selectedRegistration.state}
                    </p>
                  </div>

                  {/* QR CODE */}
                  {selectedRegistration?.qrCode && (
                    <div className="mb-4">
                      <img
                        src={IMAGE_URL + selectedRegistration?.qrCode}
                        className=" w-44"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default ContactUs;
