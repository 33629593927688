import { Helmet } from "react-helmet";

/*IMAGE */
import Checked from "../../../../assets/images/checked.png";
import { Button, Dialog, DialogBody } from "@material-tailwind/react";
import { IoIosEye } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getScannersDetailsById } from "service/api/scanner.api";
import { IMAGE_URL } from "config";

const ScannerSuccess = () => {
  /* GLOBAL VARIABLES */
  const { id } = useParams();

  const navigate = useNavigate();

  /* STATE VARIABLES */
  const [loading, setLoading] = useState(true);
  const [scannerDetails, setScannerDetails] = useState(null);

  const [viewModal, setViewModal] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(null);

  /* USE-EFFECTS */
  useEffect(() => {
    if (id) {
      setLoading(true);
      getScannersDetailsById(id)
        .then((res) => {
          setScannerDetails(res?.data);
        })
        .catch((err) => {
          console.error(err);
          setScannerDetails(null);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    }
  }, [id]);

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Success | LMP Registration</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Scanning Successful
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              See the results here
            </h2>
          </div>
        </div>

        {loading ? (
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-green-500"></div>
            </div>
          </div>
        ) : (
          <div className="bg-white max-w-lg w-full mx-auto rounded-lg shadow-md p-5 flex flex-col items-center border border-greyBorder">
            <img
              src={Checked}
              alt="Verified"
              className="object-cover h-[10vh] w-[10vh]"
            />
            <p className="text-xl font-ibm-bold pt-3 text-[#32BA7c]">
              Verification Successful
            </p>

            <div className="mt-5 text-center">
              <p className="text-xl font-ibm-bold text-black">
                {scannerDetails?.registrationId?.name}
              </p>
              <p className="pt-1 text-lg text-black font-ibm-semibold">
                ID : {scannerDetails?.registrationId?.registrationId}
              </p>
              <p className="text-lg font-ibm-regular pt-1 text-greyText">
                {scannerDetails?.registrationId?.service?.name} -{" "}
                {scannerDetails?.type}
              </p>
            </div>

            <div className="flex items-center gap-3 pt-5">
              <Button
                className="shadow-none hover:shadow-none bg-white border border-greyBorder rounded-md font-ibm-regular text-black normal-case transition-all duration-300 ease-in-out text-base"
                onClick={() => {
                  navigate("/scanner");
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  setSelectedRegistration(scannerDetails?.registrationId);
                  setViewModal(true);
                }}
                className="shadow-none hover:shadow-none bg-logoRed border border-logoRed rounded-md font-ibm-semibold text-white normal-case transition-all duration-300 ease-in-out text-base flex items-center gap-2"
              >
                <IoIosEye className="h-5 w-5" />
                View Profile
              </Button>
            </div>
          </div>
        )}

        {/* View modal */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            {/* Dialog Header */}
            <div className="border-b border-gray-200 px-5 py-3 flex items-center justify-between bg-white">
              <h5 className="text-lg font-proxima-semibold text-gray-800 tracking-tight">
                View Registration Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>

            <div className="p-5">
              {selectedRegistration && (
                <div className="p-0">
                  {/* NAME */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Name:
                      </span>{" "}
                      {selectedRegistration.name}
                    </p>
                  </div>

                  {/* EMAIL */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Email:
                      </span>{" "}
                      {selectedRegistration.email}
                    </p>
                  </div>

                  {/* PHONE */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Phone number:
                      </span>{" "}
                      {selectedRegistration.phoneNumber}
                    </p>
                  </div>

                  {/* WHATSAPP PHONE NUMBER */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Whatsapp Phone number:
                      </span>{" "}
                      {selectedRegistration.whatsAppPhoneNumber}
                    </p>
                  </div>

                  {selectedRegistration?.organization && (
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Organization:
                        </span>{" "}
                        {selectedRegistration?.organization}
                      </p>
                    </div>
                  )}

                  {selectedRegistration?.standingCommittee && (
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Standing Committee:
                        </span>{" "}
                        {selectedRegistration?.standingCommittee}
                      </p>
                    </div>
                  )}

                  {/* ZONE */}
                  {selectedRegistration?.designation && (
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Designation:
                        </span>{" "}
                        {selectedRegistration?.designation}
                      </p>
                    </div>
                  )}

                  {/* ZONE */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        Zone:
                      </span>{" "}
                      {selectedRegistration.zone}
                    </p>
                  </div>

                  {/* REGION, CITY */}
                  <div className="flex items-center gap-10">
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          Region:
                        </span>{" "}
                        {selectedRegistration.region}
                      </p>
                    </div>
                    <div className="mb-4">
                      <p className="text-md font-ibm-regular text-greyText">
                        <span className="text-black font-ibm-semibold">
                          City:
                        </span>{" "}
                        {selectedRegistration.city}
                      </p>
                    </div>
                  </div>

                  {/* STATE */}
                  <div className="mb-4">
                    <p className="text-md font-ibm-regular text-greyText">
                      <span className="text-black font-ibm-semibold">
                        State:
                      </span>{" "}
                      {selectedRegistration.state}
                    </p>
                  </div>

                  {/* QR CODE */}
                  {selectedRegistration?.qrCode && (
                    <div className="mb-4">
                      <img
                        src={IMAGE_URL + selectedRegistration?.qrCode}
                        className=" w-44"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default ScannerSuccess;
