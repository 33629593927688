import request from "../request";

/* RsvpRequest Details */
// Get RsvpRequest Form Detail List
export const getRsvpRequestList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/rsvp-request", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Patch RsvpRequest Form Details By Id
export const patchChangeStatusRsvpRequestDetailsById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`/rsvp-request/` + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete RsvpRequest Form Details By Id
export const deleteRsvpRequestDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/rsvp-request/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
